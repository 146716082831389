.error-page {
    text-align: center;
    width: 100%;
    h1 {
        font-size: $base-font-size + 30;
        line-height: 60px;
        font-weight: normal;
        @include respond-below(xs) {
            font-size: $base-font-size + 14;  
            line-height: 44px;
        }
    }
    h2 {
        font-size: $base-font-size + 30;
        line-height: 60px;
        @include respond-below(xs) {
            font-size: $base-font-size + 14;  
            line-height: 44px;
        }
    }
    img {
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
        display: block;
    }
    p {
        font-size: $base-font-size + 4;
        margin-bottom: 80px;
        a {
            color: $base-font-color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}