body {
    margin: 0;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
a {
	text-decoration: none;
	@include transition-with-prefix('all', '200ms');
}
img {
    border: 0;
    display: block;
}
h1, h2, h3, h4 {
    margin: 0;
}
ul, ol, p, dl, dt, dd {
    margin: 0;
    padding: 0;
}
ul {
    list-style-type: none;
}
:focus {
    outline: none;
}
table {
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    width: 100%;
    th {
        margin: 0;
        padding: 0;
    }
    td {
        margin: 0;
        padding: 0;
    }
}
input, textarea, select, button, a {
    outline: none;
    font-family: $font-family;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-appearance: none;
    border-radius: 0;
}
input,textarea,select, button {
    margin: 0;
    padding: 0;
    border: 0;
}
.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}
