.events-search-button {
    display: none;

    @include respond-below(xs) {
        display: block;
        width: 100%;
        background-color: $light-grey-color;
        margin: 0;
        padding: 15px 55px 14px 20px;
        border: 0;
        font-size: $base-font-size;
        line-height: 20px;
        cursor: pointer;
        margin-bottom: 30px;
        position: relative;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
            width: 55px;
            height: 100%;
            display: block;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='3.543' viewBox='0 0 10 3.543'%3e%3cg id='Group_175' data-name='Group 175' transform='translate(408.946 682.506) rotate(90)'%3e%3cpath id='Path_22' data-name='Path 22' d='M-682.156,398.946l3.193,4.849v.326l-3.217,4.825-.326-.21,2.96-4.779-2.96-4.8Z' transform='translate(0 0)'/%3e%3c/g%3e%3c/svg%3e ");
            background-repeat: no-repeat;
            background-position: right 23px top 23px;
            position: absolute;
            right: 0;
            top: 0;
            @include transition-with-prefix('transform', '200ms');
        }

        &.active {
            margin-bottom: 0;

            span {
                transform: rotate(-180deg);
            }
        }
    }
}

.events-search {
    background-color: $light-grey-color;
    padding: 20px 15px 5px 15px;
    margin: 0 0 50px 0;

    @include respond-below(xs) {
        display: none;
    }

    &.active {
        display: block;
    }

    form {
        display: flex;
        flex-wrap: wrap;

        .col-2 {
            width: calc(50% - 10px);
            margin: 0 5px 10px 5px;
            @include respond-below(xxs) {
                width: calc(100% - 10px);
            }
        }

        .col {
            width: calc(100% - 10px);
            margin: 0 5px 10px 5px;
        }

        select {
            width: 100%;
            font-size: $base-font-size;
            background-color: $white-color;
            height: 38px;
            border: 1px solid $base-border-color;
            padding: 0 55px 0 15px;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='3.543' viewBox='0 0 10 3.543'%3e%3cg id='Group_175' data-name='Group 175' transform='translate(408.946 682.506) rotate(90)'%3e%3cpath id='Path_22' data-name='Path 22' d='M-682.156,398.946l3.193,4.849v.326l-3.217,4.825-.326-.21,2.96-4.779-2.96-4.8Z' transform='translate(0 0)'/%3e%3c/g%3e%3c/svg%3e ");
            background-repeat: no-repeat;
            background-position: right 23px top 17px;
            @include respond-below(xs) {
                padding: 0 30px 0 15px; 
                background-position: right 10px top 17px;
            }
        }

        input[type="text"] {
            width: calc(100% - 32px);
            font-size: $base-font-size;
            background-color: $white-color;
            height: 36px;
            border: 1px solid $base-border-color;
            padding: 0 15px 0 15px;

            @include placeholder {
                color: $base-font-color;
            }
        }

        input[type="submit"] {
            width: 100%;
            font-size: $base-font-size;
            background-color: $base-link-color;
            color: $white-color;
            height: 38px;
            border: 0;
            padding: 0 15px 0 15px;
            cursor: pointer;
            font-weight: bold;
            @include transition-with-prefix('background-color', '200ms');

            &:hover {
                background-color: $base-font-color;
            }
        }
    }
}

.events-list-wrapper {
    .events-list-title {
        font-size: $base-font-size + 8;
        line-height: 31px;
        text-transform: uppercase;
        margin: 0 0 20px 0;
        @include respond-below(sm) {
            font-size: $base-font-size + 4;
            line-height: 26px;
            margin: 0 0 10px 0;
        }
    }

    .events-list {
        margin-bottom: 80px;

        @include respond-below(sm) {
            margin-bottom: 40px;
        }

        .event {
            display: flex;
            align-items: center;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='utf-8'?%3e%3c!-- Generator: Adobe Illustrator 25.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24.454px' height='70.43px' viewBox='0 0 24.454 70.43' enable-background='new 0 0 24.454 70.43' xml:space='preserve'%3e%3crect x='-0.001' fill='%23FFFFFF' width='24.455' height='70.43'/%3e%3cpath id='Path_249' fill='%23F4F4F4' d='M20.026,70.43c0-2.757,1.795-5.193,4.428-6.01v-5.418c-2.633-0.817-4.428-3.253-4.428-6.01 c-0.001-2.757,1.794-5.193,4.428-6.009v-5.6c-2.671-0.81-4.498-3.272-4.5-6.063c0.001-2.792,1.828-5.255,4.5-6.066v-5.913 c-2.634-0.817-4.429-3.254-4.428-6.011c0-2.757,1.795-5.192,4.428-6.008V6.29c-2.634-0.816-4.429-3.253-4.428-6.01 c0-0.094,0.008-0.187,0.013-0.28h-20.04v70.43H20.026z'/%3e%3c/svg%3e ");
            background-color: $light-grey-color;
            background-repeat: repeat-y;
            background-position: right top;
            margin-bottom: 2px;
            position: relative;

            &.disabled {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: calc(100% - 10px);
                    height: 100%;
                    background-color: $light-grey-color;
                    opacity: 0.8;
                    z-index: 30;
                }
                .event-image {
                    span {
                        background-color: $base-font-color!important;
                    }
                }
            }

            &.type1 {
                .event-image {
                    span {
                        background-color: $base-link-color;
                    }
                }
            }

            &.type2 {
                .event-image {
                    span {
                        background-color: $green-color;
                    }
                }
            }

            &.type3 {
                .event-image {
                    span {
                        background-color: $pink-color;
                    }
                }
            }

            &.type4 {
                .event-image {

                    span {
                        background-color: $orange-color;
                    }
                }
            }

            &.type5 {
                .event-image {
                    span {
                        background-color: $purple-color;
                    }
                }
            }

            .event-image {
                width: 162px;
                position: relative;

                @include respond-below(xs) {
                    width: 22px;
                    height: 130px;
                }

                a {
                    display: block;
                    position: relative;

                    @include respond-below(xs) {
                        width: 22px;
                        height: 130px;
                    }

                    img {
                        width: 140px;
                        margin-left: 22px;

                        @include respond-below(xs) {
                            display: none;
                        }

                    }
                }

                span {
                    font-size: $base-font-size - 4;
                    color: $white-color;
                    display: block;
                    height: 22px;
                    line-height: 22px;
                    width: 140px;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    position: absolute;
                    transform: rotate(-90deg);
                    left: -59px;
                    bottom: 59px;
                    z-index: 10;

                    @include respond-below(xs) {
                        left: -54px;
                        bottom: 54px;
                        width: 130px;
                    }
                }

            }

            .event-info-wrapper {
                padding: 0 0 0 20px;
                width: calc(100% - 125px);

                .event-title {
                    font-size: $base-font-size + 8;
                    line-height: 31px;
                    color: $base-font-color;
                    font-weight: bold;
                    display: block;
                    height: 62px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    margin: 0 0 8px 0;

                    @include respond-below(lg) {
                        height: 52px;
                    }

                    &:hover {
                        color: $base-link-color;
                    }

                    @include respond-below(lg) {
                        font-size: $base-font-size + 4;
                        line-height: 26px;
                    }
                }

                .event-info {
                    @include respond-below(xs) {
                        font-size: $base-font-size - 2;
                    }
                }
            }

            .event-buy {
                padding: 0 42px 0 20px;
                min-width: 80px;
                text-align: center;

                @include respond-below(xs) {
                    min-width: 40px;
                    padding: 0 20px;
                }

                a {
                    color: $red-color;
                    font-weight: bold;
                    white-space: nowrap;
                    display: inline-block;

                    &::before {
                        content: "\e914";
                        font-family: "icomoon";
                        display: block;
                        font-size: $base-font-size + 10;
                        text-align: center;
                        margin: 0 0 10px 0;

                        @include respond-below(xs) {
                            margin: 0;
                        }
                    }

                    &:hover {
                        color: $base-font-color;
                    }

                    span {
                        @include respond-below(xs) {
                            display: none;
                        }
                    }
                }
            }
        }

        .more-events {
            text-align: center;
            padding: 50px 0 0 0;

            button {
                font-size: $base-font-size;
                color: $white-color;
                font-weight: bold;
                line-height: 20px;
                padding: 12px 23px 11px 23px;
                background-color: $base-link-color;
                cursor: pointer;

                &:hover {
                    background-color: $base-font-color;
                }
            }
        }
    }
}

.event-slider-wrapper {
    @include respond-below(sm) {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            width: 0;
            display: none;
        }
    }

    .event-slider {
        margin: 0 -5px;

        @include respond-below(md) {
            margin: 0 -15px;
        }

        @include respond-below(sm) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 30px 0;
        }

        .event-slider-link {
            margin: 0 5px 10px 5px;
            width: calc(100% - 10px) !important;
            display: block !important;
            border: 1px solid $footer-border-color;

            @include respond-below(md) {
                margin: 0 15px 30px 15px;
                width: calc(100% - 30px) !important;
            }

            @include respond-below(sm) {
                margin: 0 10px 0 0;
                width: 110px !important;
                height: 110px;
            }

            img {
                width: 100%;
                max-width: 100%;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &:hover {

            .slick-next,
            .slick-prev {
                display: block !important;
            }
        }

        .slick-next,
        .slick-prev {
            position: absolute;
            width: 46px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            background-color: $dark-grey-color;
            color: $white-color;
            border-radius: 50%;
            @include transition-with-prefix('all', '200ms');
            top: calc(50% - 42px);
            z-index: $base-z-index;
            cursor: pointer;
            font-size: $base-font-size + 7;
            display: none !important;

            &:hover {
                background-color: $base-link-color;
                transform: scale(1.15);
            }
        }

        .slick-next {
            right: -18px;

            &:hover {
                transform: scale(1.15);
            }

        }

        .slick-prev {
            left: -18px;
            transform: rotate(180deg);

            &:hover {
                transform: rotate(180deg) scale(1.15);
            }
        }

        .slick-dots {
            text-align: center;
            height: 8px;
            padding-top: 20px;
            margin-bottom: 50px;
            width: 100%;

            @include respond-below(md) {
                padding-top: 10px;
            }

            li {
                display: inline-block;
                margin: 0 5px;
                height: 8px;
                vertical-align: top;

                button {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    text-indent: -9999px;
                    overflow: hidden;
                    cursor: pointer;
                    background-color: $footer-link-color;
                    opacity: 1;
                    display: block;
                }

                &.slick-active {
                    button {
                        background-color: $base-font-color;
                    }
                }
            }
        }
    }
}

.event-detail {
    h1 {
        font-size: $base-font-size + 24;
        line-height: 52px;
        margin-bottom: 5px;
        @include respond-below(sm) {
            font-size: $base-font-size + 12;
            line-height: 37px;
        }
    }

    .event-detail-info {
        font-size: $base-font-size + 2;
        line-height: 25px;
        margin-bottom: 20px;
        @include respond-below(sm) {
            font-size: $base-font-size;
            line-height: 23px;
        }
    }

    .event-detail-perex {
        display: flex;
        margin-bottom: 37px;
        @include respond-below(xs) {
            display: block;
        }

        .event-detail-perex-image {
            width: 300px;
            margin-right: 30px;
            @include respond-below(xs) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
            img {
                width: 100%;
                max-width: 100%;
            }
        }

        .event-detail-perex-right {
            width: calc(100% - 330px);
            @include respond-below(xs) {
                width: 100%;    
            }
            .event-detail-tags {
                display: flex;
                margin-bottom: 20px;

                li {
                    height: 22px;
                    line-height: 22px;
                    background-color: $footer-link-color;
                    color: $white-color;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: $base-font-size - 4;
                    margin-right: 5px;
                    padding: 0 6px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .event-detail-perex-text {
                font-size: $base-font-size + 2;
                line-height: 30px;
                @include respond-below(sm) {
                    font-size: $base-font-size;
                    line-height: 23px;
                }
            }
        }
    }

    .events-list-wrapper {
        .events-list {
            .event {
                .event-image {
                    width: 22px;
                    height: 140px;

                    span {
                        width: 140px;
                        @include respond-below(xs) {
                            left: -59px;
                            bottom: 59px;
                        }
                    }
                }
            }
            .event-info-wrapper {
                .event-title {
                    height: 31px;
                    white-space: nowrap;
                    display: block;
                    text-overflow: ellipsis;
                    margin-bottom: 0;
                }
                .event-price {
                    line-height: 20px;
                    font-size: $base-font-size;
                    margin-bottom: 19px;
                }
            }  
        }
    }
}
