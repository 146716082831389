.paginator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    @include respond-below(md) {
        margin-bottom: 50px;
    }

    a {
        height: 41px;
        line-height: 41px;
        text-align: center;
        font-size: $base-font-size;
        background-color: $base-link-color;
        color: $white-color;
        display: block;

        &:hover {
            color: $white-color;
            background-color: $black-color;
        }
    }

    span {
        height: 41px;
        line-height: 41px;
        text-align: center;
        font-size: $base-font-size;
        display: block;
        width: 41px;
        background-color: #e2e2e2;
    }

    .next,
    .prev {
        width: 160px;
        font-weight: $font-weight-bold;
        @include respond-below(xs) {
            width: 130px;    
        }
    }

    ul {
        display: flex;
        @include respond-below(xs) {
            display: none;    
        }

        li {
            margin: 0 5px;

            a {
                width: 41px;
            }
        }
    }
}
