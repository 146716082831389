.profile-wrapper {
    display: flex;
    margin-bottom: 40px;
    @include respond-below(xs) {
        display: block;    
    }
    .profile-menu-wrapper {
        width: 180px;
        margin-right: 30px;
        @include respond-below(xs) {
            width: auto;
            margin-right: 0;
            margin-bottom: 30px;
        }
        img {
            display: block;
            width: 100%;
            max-width: 100%;
        }
        .profile-menu-button {
            display: none;
            @include respond-below(xs) {
                display: block;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                background-color: #f4f4f4;
                margin: 0;
                padding: 15px 55px 14px 20px;
                border: 0;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;
                margin: 0;
                position: relative;
                text-align: left;
                span {
                    width: 55px;
                    height: 100%;
                    display: block;
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='3.543' viewBox='0 0 10 3.543'%3e%3cg id='Group_175' data-name='Group 175' transform='translate(408.946 682.506) rotate(90)'%3e%3cpath id='Path_22' data-name='Path 22' d='M-682.156,398.946l3.193,4.849v.326l-3.217,4.825-.326-.21,2.96-4.779-2.96-4.8Z' transform='translate(0 0)'/%3e%3c/g%3e%3c/svg%3e ");
                    background-repeat: no-repeat;
                    background-position: right 23px top 23px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    @include transition-with-prefix('transform', '200ms');
                }
            }
            &.active {
                span {
                    transform: rotate(-180deg);
                }
            }
        }
        .profile-menu {
            @include respond-below(xs) {
                display: none;    
            }
            &.active {
                display: block;
            }
            &.opened {
                display: block;
            }
            li {
                margin-top: 2px;
                &.active {
                    a {
                        background-color: $black-color;
                        color: $white-color;
                    }
                }
                a {
                    display: block;
                    display: flex;
                    justify-content: space-between;
                    color: $base-font-color;
                    padding: 11px 20px 10px 20px;
                    background-color: $light-grey-color;
                    &:hover {
                        background-color: $black-color;
                        color: $white-color;
                    }
                    span {
                        color: $footer-link-color;
                    }
                }
                ul {
                    li {
                        margin-top: 0;
                        a {
                            padding: 10px 20px 11px 30px;
                            strong {
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }
    .profile-right {
        width: calc(100% - 210px);
        @include respond-below(xs) {
            width: auto;    
        }
        .profile-biography {
            font-size: $base-font-size + 2;
            line-height: 30px;
            @include respond-below(sm) {
                font-size: $base-font-size;    
            }
            h2 {
                font-size: $base-font-size + 8;
                text-transform: uppercase;
                line-height: 31px;
                margin-bottom: 20px;
            }
            h3 {
                font-size: $base-font-size + 2;
            }
            .profile-info {
                margin-bottom: 30px;
            }
        }
        .profile-article-list-wrapper {
            h2 {
                font-size: $base-font-size + 8;
                line-height: 31px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            .profile-article-list {
                li {
                    border-bottom: 1px solid $base-border-color;
                    padding: 0 0 20px 0;
                    margin: 0 0 20px 0;
                    &:last-child {
                        border-bottom: 0;
                    }
                    .profile-article-list-info {
                        display: flex;
                        font-size: $base-font-size - 3;
                        line-height: 17px;
                        margin-bottom: 10px;
                        .date {
                            color: $footer-link-color;
                            &::after {
                                content: "|";
                                margin: 0 5px;
                                display: inline-block;
                            }
                        }
                        a {
                            color: $base-font-color;
                            &:hover {
                                color: $base-link-color;
                            }
                        }
                    }
                    .profile-article-list-content {
                        display: flex;
                        @include respond-below(xxs) {
                            display: block;    
                        }
                        .profile-article-list-image {
                            width: 140px;
                            margin-right: 30px;
                            display: block;
                            @include respond-below(xxs) {
                                width: auto;
                                margin-right: 0;
                                margin-bottom: 10px;
                            }
                            img {
                                display: block;
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                        .profile-article-list-title {
                            width: calc(100% - 170px);
                            font-size: $base-font-size + 2;
                            line-height: 23px;
                            font-weight: bold;
                            color: $base-font-color;
                            &:hover {
                                color: $base-link-color;
                            }
                            @include respond-below(xxs) {
                                width: auto;
                                
                            }
                        }
                    }
                }
            }
        }
        .profile-submenu {
            display: flex;
            margin: 0 -1px 30px -1px;
            li {
                margin: 0 1px;
                width: calc(100% / 3);
                &.active {
                    a {
                        background-color: $base-font-color;
                        color: $white-color;
                    }
                }
                a {
                    display: block;
                    height: 41px;
                    line-height: 41px;
                    text-align: center;
                    font-size: $base-font-size;
                    background-color: $light-grey-color;
                    color: $base-font-color;
                    font-weight: $font-weight-bold;
                }
            }
        }
        .song-detail {
            .song-text {
                .song-text-left {
                    width: calc(100% - 160px);
                }
                .song-text-right {
                    width: 110px;
                }
            }
        }
    }
}