footer {
    background-color: $dark-grey-color;
    color: $white-color;
    padding: 40px 0 50px 0;

    @include respond-below(xxs) {
        padding: 25px 0 30px 0;
    }

    a {
        color: $white-color;

        &:hover {
            color: $base-link-color;
        }
    }

    .footer-inner {
        margin: 0 auto;
        max-width: $base-width;

        @include respond-below(lg) {
            padding: 0 30px;
        }

        @include respond-below(sm) {
            padding: 0 20px;
        }

        #footer-accordion {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 44px;

            @include respond-below(md) {
                margin-bottom: 0;
            }

            @include respond-below(xxs) {
                display: block;
            }

            .footer-accordion-data {
                width: 25%;

                @include respond-below(md) {
                    width: 50%;
                    margin-bottom: 40px;
                }

                @include respond-below(xxs) {
                    width: auto;
                    margin-bottom: 0;
                }

                a {
                    font-weight: $font-weight-bold;
                    color: $white-color;
                    line-height: 25px;
                    text-transform: uppercase;
                    font-size: $base-font-size;
                    text-align: left;
                    margin-bottom: 25px;
                    display: inline-block;

                    &:hover {
                        color: $base-link-color;
                    }
                    @include respond-below(xxs) {
                        margin-bottom: 15px;    
                    }

                }

                button {
                    display: none;

                    @include respond-below(xxs) {
                        display: block;
                        background-color: transparent;
                        color: $white-color;
                        transform: rotate(90deg);
                        font-size: $base-font-size - 6;
                        float: right;
                        width: 20px;
                        height: 20px;
                        margin-right: -5px;
                        margin-top: 2px;
                        @include transition-with-prefix('transform', '200ms');
                    }
                }
                &.open {
                    button {
                        @include respond-below(xxs) {
                            transform: rotate(270deg);    
                        }
                    }
                }
            }

            .footer-accordion-data-content {
                @include respond-above(xxs) {
                    max-height: none !important;
                    overflow: visible !important;
                }

                ul {
                    li {
                        a {
                            color: $footer-link-color;
                            text-transform: none;
                            margin-bottom: 0;
                            font-weight: $font-weight-normal;
                        }
                    }

                    @include respond-below(xxs) {
                        padding-bottom: 30px;
                    }
                }
            }
        }

        .footer-bottom {
            border-top: 1px solid $footer-border-color;
            display: flex;
            justify-content: space-between;
            padding: 20px 0 0 0;

            @include respond-below(xxs) {
                display: block;
            }

            .copyright {
                color: $footer-link-color;
                font-size: $base-font-size - 2;

                @include respond-below(xxs) {
                    margin-bottom: 14px;
                }
            }

            .footer-social-links {
                display: flex;
                padding: 3px 0 0 0;

                li {
                    margin-left: 20px;

                    a {
                        font-size: $base-font-size + 2;
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
