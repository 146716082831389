.box-messages {
    margin-bottom: 40px;

    .box-messages-nav {
        display: flex;
        margin-bottom: 16px;

        li {
            font-size: $base-font-size + 2;
            
            @include respond-below(sm) {
                font-size: $base-font-size;    
            }

            &::after {
                content: "|";
                display: inline-block;
                margin: 0 4px;
            }

            &:last-child::after {
                display: none;
            }

            &.active {
                a {
                    font-weight: $font-weight-bold;
                }
            }

            a {
                color: $base-font-color;
                text-transform: uppercase;
            }
        }
    }

    .box-messsages-tabs {
        ul {
            border-left: 1px solid $base-border-color;
            padding: 5px 0 5px 0;

            li {
                margin-bottom: 20px;
                position: relative;
                padding: 0 0 0 16px;

                &::before {
                    content: "\e901";
                    font-family: "icomoon";
                    font-size: $base-font-size - 8px;
                    position: absolute;
                    left: -4px;
                    top: 6px;
                }

                a {
                    color: $base-font-color;
                    line-height: 20px;

                    &:hover {
                        color: $base-link-color;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    @include respond-below(md) {
        width: calc(50% - 30px);
        margin: 0 15px 40px 15px;
    }
    @include respond-below(xs) {
        width: auto;
        margin: 0 0 25px 0;
    }
}
