#header {
    background-color: $white-color;
    border-bottom: 1px solid $base-border-color;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    @include transition-with-prefix('top', '200ms');
    z-index: $base-z-index + 10;
    &.nav-up {
        top: -135px;
        @include respond-below(sm) {
            top: -51px;
        }
    }
    .header-inner {
        display: flex;
        justify-content: space-between;
        max-width: $base-width;
        margin: 0 auto;
        align-items: center;
        position: relative;

        @include respond-below(lg) {
            padding: 0 30px;
        }
        
        @include respond-below(sm) {
            padding: 0 20px;
        }
        .search-form {
            position: absolute;
            left: 0;
            bottom: -58px;
            background-color: $black-color;
            color: $white-color;
            width: 100%;
            display: none;
            &.active {
                display: flex;
            }
            @include respond-below(sm) {
                display: none!important;    
            }
            input {
                background-color: $black-color;
                color: $white-color;
                width: calc(100% - 101px);
                padding: 0 20px 0 23px;
                font-size: $base-font-size;
                @include placeholder {
                    color: $white-color;
                }
            }
            button {
                color: $white-color;
                background-color: transparent;
                font-size: $base-font-size + 2;
                width: 58px;
                height: 58px;
                line-height: 58px;
                text-align: center;
                cursor: pointer;
                &:hover {
                    color: $base-link-color;
                }
            }
        }
        .header-left {
            display: flex;
            align-items: center;

            .logo {
                margin-right: 30px;

                @include respond-below(md) {
                    margin: 24px 30px 25px 0;
                }
                @include respond-below(sm) {
                     margin: 15px 0 10px 0;   
                }
                .icon-logo {
                    display: inline-block;
                    font-size: $base-font-size + 9;

                    @include respond-below(sm) {
                        font-size: $base-font-size + 7;
                    }
                }
            }

            nav {
                ul {
                    display: flex;

                    li {
                        position: relative;

                        &:hover {
                            ul {
                                display: block;
                            }
                        }

                        a {
                            font-size: $base-font-size + 2;
                            font-weight: $font-weight-bold;
                            color: $base-font-color;
                            text-transform: uppercase;
                            height: 76px;
                            line-height: 76px;
                            display: block;
                            padding: 0 20px;

                            &:hover {
                                color: $base-link-color;
                            }

                            @include respond-below(lg) {
                                font-size: $base-font-size;
                                padding: 0 12px;
                            }
                        }

                        ul {
                            position: absolute;
                            background-color: $submenu-bg-color;
                            display: none;
                            padding: 10px 0;
                            width: 148px;
                            left: calc(50% - 74px);

                            li {
                                padding: 0;
                                display: block;

                                a {
                                    padding: 5px 24px 5px 24px;
                                    color: $white-color;
                                    font-weight: $font-weight-normal;
                                    font-size: $base-font-size;
                                    display: block;
                                    text-transform: none;
                                    height: auto;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }

                @include respond-below(md) {
                    display: none;
                }
            }
        }

        .header-right {
            display: flex;
            align-items: center;

            .header-menu {
                display: flex;
                margin-right: 30px;
                margin-top: 2px;

                @include respond-below(sm) {
                    display: none;
                }

                li {
                    margin: 0 10px;

                    a {
                        color: $base-font-color;

                        &:hover {
                            color: $base-link-color;
                        }
                    }

                    .icon-instagram {
                        font-size: $base-font-size + 2;
                    }

                    .icon-facebook {
                        font-size: $base-font-size + 2;
                    }

                    .icon-login {
                        font-size: $base-font-size + 2;
                    }

                    .search-open {
                        font-size: $base-font-size + 2;
                        background-color: $white-color;
                        cursor: pointer;

                        &:hover {
                            color: $base-link-color;
                        }
                    }
                }
            }

            .logo-predpredaj {
                margin-right: 20px;

                @include respond-below(sm) {
                    display: none;
                }

                img {
                    height: 23px;
                }
            }

            .logo-zoznam {
                img {
                    height: 23px;
                    @include respond-below(sm) {
                        height: 18px;
                    }
                }
            }

            .icon-menu {
                background-color: transparent;
                margin-left: 45px;
                cursor: pointer;
                display: none;
                font-size: $base-font-size - 2;
                span {
                    display: block;
                    font-family: $font-family;
                    font-size: $base-font-size - 2;
                    line-height: 18px;
                    padding: 2px 0 0 0;
                    @include respond-below(sm) {
                        font-size: $base-font-size - 4;
                        line-height: 15px;
                        padding: 0;
                    }
                }

                @include respond-below(md) {
                    display: block;
                }
                @include respond-below(sm) {
                    margin: 5px 0 0 15px;
                }    
            }
        }
    }
}
