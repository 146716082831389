.song-detail {
    margin-bottom: 30px;
    h1 {
        font-size: $base-font-size + 24;
        line-height: 52px;
        margin-bottom: 2px;
        @include respond-below(sm) {
            font-size: $base-font-size + 12;
            line-height: 37px;
        }
    }

    h2 {
        font-size: $base-font-size + 8;
        line-height: 31px;
        font-weight: normal;
        margin-bottom: 30px;
        @include respond-below(sm) { 
            font-size: 20px;
        }
    }

    .song-video-wrapper {
        max-width: 350px;
        margin: 0 auto 30px auto;

        .song-video {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .song-text {
        font-size: $base-font-size + 2;
        display: flex;
        flex-wrap: wrap;
        @include respond-below(sm) {
            display: block;   
            font-size: $base-font-size;
        }
        .song-text-full-width {
            width: 100%;
            p {
                margin-bottom: 30px;
                line-height: 1.6em;
            }
        }
        .song-text-left {
            margin-right: 50px;
            width: calc(100% - 270px);
            @include respond-below(sm) {
                width: auto;   
                margin-right: 0;
            }
            .transposition {
                margin-bottom: 10px;
            }
            .song-scale {
                display: flex;
                justify-content: space-between;
                margin-bottom: 35px;
                li {
                    flex: 1 0 0;
                    a {
                        display: block;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        border-top: 1px solid $base-border-color;
                        border-bottom: 1px solid $base-border-color;
                        border-left: 1px solid $base-border-color;
                        color: $base-font-color;
                        &:hover {
                            color: $base-link-color;
                        }
                    }
                    &.active {
                        a {
                            background-color: $base-link-color;
                            border-top: 1px solid $base-link-color;
                            border-bottom: 1px solid $base-link-color;
                            border-left: 1px solid $base-link-color;
                            color: $white-color;
                        }
                    }
                    &:last-child {
                        a {
                            border-right: 1px solid $base-border-color;
                        }
                    }
                }
            }
            .song-content {
                line-height: 40px;
                p {
                    margin-bottom: 30px;
                    sup {
                        font-size: $base-font-size + 4;
                        font-weight: bold;
                        color: $base-link-color;
                        @include respond-below(sm) {
                            font-size: $base-font-size + 2;    
                        }
                    }
                }
            }
        }

        .song-text-right {
            width: 220px;
            padding-top: 33px;
            @include respond-below(sm) {
                width: auto;    
            }
            select {
                width: 100%;
                font-size: $base-font-size;
                background-color: $white-color;
                height: 42px;
                border: 1px solid $base-border-color;
                padding: 0 40px 0 15px;
                margin: 0 0 20px 0;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='3.543' viewBox='0 0 10 3.543'%3e%3cg id='Group_175' data-name='Group 175' transform='translate(408.946 682.506) rotate(90)'%3e%3cpath id='Path_22' data-name='Path 22' d='M-682.156,398.946l3.193,4.849v.326l-3.217,4.825-.326-.21,2.96-4.779-2.96-4.8Z' transform='translate(0 0)'/%3e%3c/g%3e%3c/svg%3e ");
                background-repeat: no-repeat;
                background-position: right 15px top 19px;
            }
            .song-text-right-image {
                display: flex;
                flex-wrap: wrap;
                img {
                    margin: 0 15px 20px 15px;
                }
            }
        }
        .song-print {
            width: 100%;
            border-top: 1px solid $base-border-color;
            padding: 15px 0 0 0;
            button {
                background-color: $white-color;
                font-size: $base-font-size + 2; 
                cursor: pointer;
                @include respond-below(sm) {
                    font-size: $base-font-size;    
                }
                &:hover {
                    color: $base-link-color;
                }
                i {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }
    }

}
