@function unicode($str) {
    @return unquote("\"") + unquote(str-insert($str, "\\", 1)) + unquote("\"");
}

@include icomoon('icomoon', '../fonts/icomoon', (
    play-circle: unicode("e917"),
    arrow-big: unicode("e900"),
    arrow: unicode("e901"),
    close: unicode("e902"),
    facebook: unicode("e903"),
    facebook1: unicode("e904"),
    instagram: unicode("e905"),
    login: unicode("e906"),
    logo: unicode("e907"),
    magnifier: unicode("e908"),
    mail: unicode("e909"),
    menu: unicode("e90a"),
    message: unicode("e90b"),
    messenger: unicode("e90c"),
    music-album: unicode("e90d"),
    music-list: unicode("e90e"),
    music-play: unicode("e90f"),
    music-video: unicode("e910"),
    music: unicode("e911"),
    plus: unicode("e912"),
    print: unicode("e913"),
    ticket: unicode("e914"),
    twitter: unicode("e915"),
    whatsapp: unicode("e916")
    
))
