.breadcrumbs {
    font-size: $base-font-size - 2;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @include respond-below(xs) {
        display: block;
    }
    ul {
        display: flex;

        li {
            color: $footer-link-color;

            &::after {
                content: "/";
                display: inline-block;
                margin: 0 4px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            a {
                color: $base-font-color;

                &:hover {
                    color: $base-link-color;
                }
            }
        }
    }
    .breadcrumbs-top-info {
        color: $footer-link-color;
        a {
            color: $base-font-color;
            &:hover {
                color: $base-link-color;
            }
        }
        span {
            display: inline-block;
            margin: 0 5px;
        }
    }
}
