.catalog-wrapper {
    .box-section {
        .box-section-title {
            line-height: 31px !important;
            font-size: $base-font-size + 8 !important;
        }
        .album-info {
            margin-bottom: 20px;
            border-bottom: 1px solid $base-border-color;
            padding-bottom: 20px;
        }
        &.col-4 {
            .article-item {
                margin: 0 5px 20px 5px !important;
            }
            .box-section-title {
                margin: 0 5px 20px 5px!important;
            }
        }

        &.col-2 {
            
            .article-item {
                .article-item-more {
                    margin: 0 !important;
                    width: 100% !important;

                    a {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
        .box-section-title {
            margin: 0 0 20px 0 !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @include respond-below(xs) {
                overflow: visible;
                white-space: normal;
                text-overflow: clip;
            }
        }
        .article-item-list {
            &.col-2 {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;
                @include respond-below(xs) {
                    display: block;
                    margin: 0;
                }
                li {
                    width: calc(50% - 30px);
                    margin: 0 15px 20px 15px;
                    @include respond-below(xs) {
                        width: auto;
                        margin: 0 0 20px 0;
                    }
                    &:nth-last-child(-n+2) {
                        border-bottom: 0;
                        @include respond-below(xs) {
                            border-bottom: 1px solid $base-border-color;  
                            &:last-child {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 0 14px 0;
                margin: 0 0 14px 0;
                border-bottom: 1px solid $base-border-color;

                .article-item-list-left {
                    font-size: $base-font-size;
                    line-height: 20px;
                    width: calc(100% - 100px);
                    &.full-width {
                        width: 100%;
                    }
                    a {
                        color: $base-font-color;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        max-width: 100%;

                        &:hover {
                            color: $base-link-color;
                        }
                    }

                    div {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .article-item-list-right {
                    i {
                        font-size: $base-font-size + 2;
                        color: $base-border-color;
                        margin: 0 8px;
                    }

                    a {
                        i {
                            color: $base-link-color;
                            @include transition-with-prefix('color', '200ms');

                        }

                        &:hover {
                            i {
                                color: $base-font-color;
                            }
                        }
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
    .performer-add {
        display: inline-block;
        background-color: $light-green-bg-color;
        color: $white-color;
        font-weight: bold;
        padding: 10px 26px 11px 15px;
        margin: 1px 0 40px 0;
        span {
            display: inline-block;
            margin-right: 15px;
        }
    }
    .performer-search-button {
        display: none;
        @include respond-below(xs) {
            display: block;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: $light-grey-color;
            margin: 0;
            padding: 15px 55px 14px 20px;
            border: 0;
            font-size: $base-font-size;
            line-height: 20px;
            cursor: pointer;
            margin: 0;
            position: relative;
            text-align: left;  
            span {
                width: 55px;
                height: 100%;
                display: block;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='3.543' viewBox='0 0 10 3.543'%3e%3cg id='Group_175' data-name='Group 175' transform='translate(408.946 682.506) rotate(90)'%3e%3cpath id='Path_22' data-name='Path 22' d='M-682.156,398.946l3.193,4.849v.326l-3.217,4.825-.326-.21,2.96-4.779-2.96-4.8Z' transform='translate(0 0)'/%3e%3c/g%3e%3c/svg%3e ");
                background-repeat: no-repeat;
                background-position: right 23px top 23px;
                position: absolute;
                right: 0;
                top: 0;
                @include transition-with-prefix('transform', '200ms');
            }
        }
        &.active {
            span {
                transform: rotate(-180deg);
            }
        }
    }
    .performer-search {
        &.active {
            display: block;
        }
        &.opened {
            display: block;
        }
        background-color: $light-grey-color;
        padding: 20px 15px 20px 15px;
        form {
            display: flex;
            flex-wrap: wrap;
            @include respond-below(xxs) {
                display: block;  
            }
            input[type="text"] {
                font-size: $base-font-size;
                background-color: $white-color;
                height: 36px;
                border: 1px solid $base-border-color;
                padding: 0 15px;
                margin: 0 5px;
                width: calc(50% - 40px);
                @include placeholder {
                    color: $base-font-color;
                }
                @include respond-below(xxs) {
                    width: calc(100% - 42px);
                    margin-bottom: 10px;
                }
            }
            input[type="submit"] {
                font-size: $base-font-size;
                background-color: $base-link-color;
                color: $white-color;
                height: 38px;
                border: 0;
                padding: 0 15px;
                font-weight: bold;
                cursor: pointer;
                margin: 0 5px;
                width: calc(50% - 12px);
                @include transition-with-prefix('background-color', '200ms');
                &:hover {
                    background-color: $black-color;
                }
                @include respond-below(xxs) {
                    width: calc(100% - 10px);    
                }
            }
            ul {
                width: 100%;
                display: flex;
                padding: 20px 5px 0 5px;
                justify-content: space-between;
                @include respond-below(xs) {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
                li {
                    text-transform: uppercase;
                    color: $footer-link-color;
                    @include respond-below(xs) {
                        margin-right: 10px;    
                    }
                    a {
                        color: $base-font-color;
                        &:hover {
                            color: $base-link-color;
                        }
                    }
                }
            }
        }
        @include respond-below(xs) {
            display: none;    
        }
    }
    .catalog-list-title {
        font-size: $base-font-size + 8;
        line-height: 31px;
        text-transform: uppercase;
        margin: 20px 0;
    }
    .catalog-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 60px -10px;
        line-height: 24px;
        font-size: $base-font-size;
        @include respond-below(xs) {
            display: block;
            margin: 0 0 60px 0;
        }
        li {
            width: calc(50% - 20px);
            margin: 0 10px;
            @include respond-below(xs) {
                width: auto;
                margin: 0;
            }
            a {
                color: $base-font-color;
                &:hover {
                    color: $base-link-color;
                }
            }
        }
    }
}
