body {
    font-family: $font-family;
    font-size: $base-font-size;
    font-weight: $font-weight-normal;
    color: $base-font-color;

    a {
        color: $base-link-color;

        &:hover {
            color: $base-font-color;
        }
    }

    .main-wrapper {
        padding-top: 77px;

        @include respond-below(sm) {
            padding-top: 51px;
        }

        .main {
            max-width: $base-width;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            padding: 40px 0 0 0;

            @include respond-below(lg) {
                padding: 20px 30px 0 30px;
            }

            @include respond-below(md) {
                display: block;
            }

            @include respond-below(sm) {
                padding: 20px 20px 0 20px;
            }

            .main-inner {
                width: 100%;
                background-color: red;
            }

            .main-inner-left {
                width: calc(100% - 390px);
                margin-right: 30px;

                @include respond-below(md) {
                    width: auto;
                    margin-right: 0;
                }

                .main-title {
                    font-size: $base-font-size + 18px;
                    line-height: 44px;
                    text-transform: uppercase;
                    margin-bottom: 26px;
                    &.profile {
                        text-transform: none;
                        font-size: $base-font-size + 24px;
                        line-height: 52px;
                    }
                    @include respond-below(sm) {
                        font-size: 24px;
                        line-height: 31px;
                    }
                }

                .box-section {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -15px;

                    @include respond-below(xs) {
                        margin: 0;
                    }

                    &.with-slider {
                        display: block;

                        &.with-border::after {

                            content: "";
                            border-bottom: 1px solid $base-border-color;
                            display: block;
                            height: 0;
                            width: calc(100% - 30px);
                            margin: 0 15px 30px 15px;

                            @include respond-below(xs) {
                                width: auto;
                                margin: 0 0 25px 0;
                            }

                        }

                        .box-section-slider {
                            .slick-dots {
                                text-align: center;
                                height: 8px;
                                margin-bottom: 40px;

                                @include respond-below(sm) {}

                                li {
                                    display: inline-block;
                                    margin: 0 5px;
                                    height: 8px;
                                    vertical-align: top;

                                    button {
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 50%;
                                        text-indent: -9999px;
                                        overflow: hidden;
                                        cursor: pointer;
                                        background-color: $black-color;
                                        opacity: 0.3;
                                        display: block;
                                    }

                                    &.slick-active {
                                        button {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }

                            .article-item {
                                width: calc(100% - 30px) !important;
                                margin: 0 15px 30px 15px;

                                @include respond-below(xs) {
                                    width: auto !important;
                                    margin: 0 0 30px 0;
                                }

                                .article-item-link {
                                    .article-item-title {
                                        font-size: $base-font-size + 8;
                                        line-height: 31px;

                                        @include respond-below(sm) {
                                            line-height: 26px;
                                            font-size: $base-font-size + 4;
                                        }
                                    }
                                }

                            }
                        }
                    }
                    .article-item-more {
                        a {
                            display: inline-block;
                            height: 41px;
                            line-height: 41px;
                            padding: 0 39px;
                            margin-bottom: 40px;
                            font-weight: bold;
                            background-color: $base-link-color;
                            color: $white-color;
                            &:hover {
                                background-color: $black-color;
                            }
                        }
                    }
                    .box-section-title {
                        font-size: $base-font-size + 18;
                        line-height: 44px;
                        text-transform: uppercase;
                        width: 100%;
                        margin: 0 15px 20px 15px;

                        @include respond-below(sm) {
                            font-size: $base-font-size + 8;
                            line-height: 31px;
                        }

                        @include respond-below(xs) {
                            margin: 0 0 20px 0;
                        }
                    }

                    &.col-4 {
                        .box-section-title {
                            margin: 0 5px 20px 5px;
                        }
                    }
                    
                    .article-item {
                        width: calc(100% - 30px);
                        margin: 0 15px 40px 15px;
                        @include respond-below(xs) {
                            margin: 0 0 25px 0;
                            width: 100%;
                        }

                        .article-item-info {
                            font-size: $base-font-size - 3px;
                            line-height: 17px;
                            display: flex;
                            color: $footer-link-color;
                            padding-top: 10px;

                            li {
                                &::after {
                                    content: "|";
                                    display: inline-block;
                                    margin: 0 4px;
                                }

                                &:last-child {
                                    &::after {
                                        display: none;
                                    }
                                }

                                a {
                                    color: $base-font-color;

                                    &:hover {
                                        color: $base-link-color;
                                    }
                                }
                            }
                        }

                        .article-item-link {
                            display: block;
                            &.center {
                                text-align: center;
                            }
                            &:hover {
                                .article-item-title {
                                    color: $base-link-color;
                                }

                                .article-item-image {
                                    &.video {
                                        &::before {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }

                            .article-item-image {
                                margin-bottom: 10px;

                                &.video {
                                    position: relative;

                                    &::before {
                                        content: "\e917";
                                        font-family: "icomoon";
                                        color: $white-color;
                                        font-size: $base-font-size + 34;
                                        display: block;
                                        position: absolute;
                                        left: calc(50% - 25px);
                                        top: calc(50% - 25px);
                                        opacity: 0.3;
                                        @include transition-with-prefix('opacity', '200ms');
                                    }
                                }

                                img {
                                    max-width: 100%;
                                    width: 100%;
                                }

                            }

                            .article-item-title {
                                font-size: $base-font-size + 18;
                                line-height: 44px;
                                color: $base-font-color;
                                display: block;
                                font-weight: $font-weight-bold;
                                @include transition-with-prefix('color', '200ms');

                                span {
                                    display: block;
                                    font-weight: $font-weight-normal;
                                }

                                @include respond-below(sm) {
                                    font-size: $base-font-size + 8;
                                    line-height: 29px;
                                }
                            }
                        }


                    }

                    &.col-2 {
                        @include respond-below(xs) {
                            display: block;
                            margin: 0;
                        }
                        .article-item-more {
                            width: calc(100% - 10px);
                            margin: 0 5px;    
                        }
                        .article-item {
                            width: calc(50% - 30px);

                            @include respond-below(xs) {
                                width: auto;
                                margin: 0 0 25px 0;
                            }

                            .article-item-link {
                                .article-item-title {
                                    font-size: $base-font-size + 8;
                                    line-height: 31px;

                                    @include respond-below(sm) {
                                        font-size: $base-font-size + 4;
                                        line-height: 26px;
                                    }
                                }
                            }

                        }

                        &.with-border::after {

                            content: "";
                            border-bottom: 1px solid $base-border-color;
                            display: block;
                            height: 0;
                            width: calc(100% - 30px);
                            margin: 0 15px 30px 15px;

                            @include respond-below(xs) {
                                margin: 0 0 20px 0;
                                width: auto;
                            }

                        }
                    }

                    &.col-4 {

                        &.with-border::after {

                            content: "";
                            border-bottom: 1px solid $base-border-color;
                            display: block;
                            height: 0;
                            width: calc(100% - 10px);
                            margin: 0 5px 30px 5px;

                        }

                        margin: 0 -5px;
                        
                        .article-item-more {
                            width: calc(100% - 10px);
                            margin: 0 5px;    
                        }
                        .article-item {
                            width: calc(25% - 10px);
                            margin: 0 5px 40px 5px;

                            @include respond-below(xs) {
                                width: calc(50% - 10px);
                                margin: 0 5px 20px 5px;
                            }

                            .article-item-title {
                                font-size: $base-font-size;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }

            hr {
                padding: 0;
                margin: 0 0 30px 0;
                height: 1px;
                border: 0;
                background-color: $base-border-color;
            }

            .main-inner-right {
                width: 360px;

                @include respond-below(md) {
                    width: auto;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -15px;

                    &.with-border::after {

                        content: "";
                        border-bottom: 1px solid $base-border-color;
                        display: block;
                        height: 0;
                        width: calc(100% - 30px);
                        margin: 0 15px 30px 15px;

                        @include respond-below(xs) {
                            display: none;
                        }

                    }
                }

                @include respond-below(xs) {
                    display: block;
                    margin: 0;
                }
            }
        }
    }
}
