.box-advertisement {
    text-align: center;
    margin-bottom: 32px;
    img {
        display: inline-block;
    }
    @include respond-below(md) {
        width: calc(50% - 30px);
        margin: 0 15px 40px 15px;
    }
    @include respond-below(xs) {
        width: auto;
        margin: 0 -10px 25px -10px;
    }
}