@media print {
    .main-inner-right {
        display: none !important;
    }

    footer {
        display: none;
    }

    .menu-mobile {
        display: none;
    }

    header {
        display: none;
    }

    .breadcrumbs {
        display: none;
    }

    .profile-submenu {
        display: none !important;
    }

    .profile-menu-button {
        display: none !important;
    }

    .profile-menu-wrapper img {
        display: none !important;
    }

    .profile-right {
        .song-text {
            display: block!important;

            .song-text-left {
                width: auto!important;
            }
            .song-text-right {
                width: auto!important;
            }
        }

    }

    .song-detail {

        .song-video-wrapper {
            display: none;
        }

        .song-text {
            .song-text-left {
                .transposition {
                    display: none;
                }

                .song-scale {
                    display: none;
                }
            }

            .song-text-right {
                select {
                    display: none;
                }
            }

            .song-print {
                display: none;
            }
        }
    }


}
