.calendar {
    position: relative;
    overflow: hidden;
    text-align: center;
    header {
        .simple-calendar-btn {
            display: inline-block;
            position: absolute;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            border-radius: 50%;
        }

        .simple-calendar-btn:hover {
            color: $white-color;
        }

        .simple-calendar-btn:before {
            content: '';
            position: absolute;
            top: 9px;
            left: 8px;
            width: 8px;
            height: 8px;
            border-style: solid;
            border-width: 3px 3px 0 0;
            transform: rotate(45deg);
            transform-origin: center center;
        }

        .btn-prev {
            top: 0;
            left: 0;
            transform: rotate(-180deg);
        }

        .btn-next {
            top: 0;
            right: 0;

            &:before {
                transform: rotate(45deg);
            }
        }

        .month {
            padding: 0;
            margin: 0;

            .year {
                font-size: 0.6em;
                font-weight: 100;
            }
        }
    }

    table {
        width: 100%;
        margin: 20px 0;
        border-spacing: 0px;
    }

    thead {
        font-size: 1.2em;
        font-weight: 600;
    }

    td {
        padding: .8em .1em;
    }

    .day {
        position: relative;
        display: inline-block;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        border-radius: 50%;
        border: 2px solid transparent;
        cursor: pointer;

        &.today {
            color: $white-color;

            &.has-event:after {
                background: $white-color;
            }
        }

        &.wrong-month {
            
        }

        &.wrong-month:hover {
            border: 2px solid transparent;
        }

        &.has-event {
            &:after {
                content: '';
                position: absolute;
                top: calc(50% + .6em);
                left: calc(50% - 2px);
                width: 4px;
                height: 4px;
                border-radius: 50%;
            }
        }

        &.disabled {
            cursor: default;

            &:hover {
                border: 2px solid transparent;
            }
        }
    }

    .event-container {
        display: none;
        position: absolute;
        top: 33px;
        left: 0;
        width: calc(100% - 40px);
        padding: 0 20px;
        height: 100%;
        z-index: $base-z-index;
        background: $dark-grey-color;

        .event-wrapper {
            overflow-y: auto;
            height: 100%;
            padding: 15px 0 0 0;
            margin: 35px 0 0 0;
            border-top: 1px solid $predpredaj-calendar-event-bg-color;
            -webkit-overflow-scrolling: touch;
        }

        .close {
            position: absolute;
            cursor: pointer;
            right: 20px;
            top: 0;
            &::before {
                content: "\e902";
                font-family: "icomoon";
                font-size: $base-font-size - 4;
            }
            &:hover {
                color: $base-link-color;
            }
        }
        .day-info {
            position: absolute;
            left: 20px;
        }

        .event {
            display: flex;
            text-align: left;
            .event-summary {
                width: calc(100% - 50px);
                margin-bottom: 15px;
                a {
                    font-weight: $font-weight-bold;
                    color: $white-color; 
                    &:hover {
                        color: $base-link-color;
                    }
                }
                
            }
            .event-hour {
                width: 50px;
                text-align: right;
                color: $footer-link-color;
            }
        }
        .more-events {
            text-align: left;
            a {
                color: $white-color;
                &:after {
                    content: '\e900';
                    font-family: "icomoon";
                    font-size: $base-font-size - 4;
                    margin-left: 8px;
                }
                &:hover {
                    color: $base-link-color;
                }
            }
        }
    }

    .filler {
        position: absolute;
        width: 0;
        height: 0;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
}
