.article-detail {
    border-bottom: 1px solid $base-border-color;
    margin-bottom: 30px;

    h1 {
        font-size: $base-font-size + 24;
        line-height: 52px;
        margin-bottom: 20px;

        @include respond-below(sm) {
            font-size: $base-font-size + 12;
            line-height: 37px;
        }
    }

    h2 {
        font-size: $base-font-size + 12;
        line-height: 30px;
        margin-bottom: 34px;
        padding: 26px 0 0 0;

        @include respond-below(sm) {
            font-size: $base-font-size + 6;
            line-height: 28px;
        }
    }

    h3 {
        font-size: $base-font-size + 8;
        line-height: 30px;
        margin-bottom: 32px;
        padding: 29px 0 0 0;

        @include respond-below(sm) {
            font-size: $base-font-size + 2;
            line-height: 28px;
        }
    }

    .box-advertisement {
        margin-bottom: 32px;
        width: auto;

        @include respond-below(sm) {
            margin-bottom: 22px;
        }
    }

    .article-image {
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;

        @include respond-below(sm) {
            margin: 0 -20px 10px -20px;
            width: calc(100% + 40px);
            max-width: none;
        }
    }

    .article-image-link {
        display: block;
        position: relative;
        margin-bottom: 10px;
        &:hover {
            &::after {
                display: block;
                @include respond-below(sm) {
                    display: none;    
                }
            }
        }
        img {
            width: 100%;
            max-width: 100%;
        }

        &::after {
            content: "\e908";
            font-family: "icomoon";
            color: $white-color;
            font-size: $base-font-size + 2;
            position: absolute;
            left: calc(50% - 25px);
            top: calc(50% - 25px);
            width: 44px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            border-radius: 50%;
            border: 3px solid #fff;
            display: none;
        }

        @include respond-below(sm) {
            margin: 0 -20px 10px -20px;
            width: calc(100% + 40px);
            max-width: none;
        }
    }

    .article-source-info {
        text-align: center;
        font-size: $base-font-size - 2;
        line-height: 18px;
        margin-bottom: 30px;

        @include respond-below(sm) {
            font-size: $base-font-size - 3;
            line-height: 17px;
        }

        .article-source-title {
            display: block;
        }

        .article-source {
            color: $footer-link-color;
        }
    }

    .article-text {
        padding: 0 40px 30px 40px;
        font-size: $base-font-size + 2;
        line-height: 30px;

        @include respond-below(sm) {
            font-size: $base-font-size;
            line-height: 28px;
            padding: 0 0 30px 0;
        }

        @include respond-below(xs) {
            padding: 0 0 20px 0;
        }

        p {
            margin-bottom: 29px;
        }

        ul {
            list-style-type: disc;
            margin: 0 0 33px 40px;

            @include respond-below(sm) {
                margin: 0 0 33px 20px;
            }

            li {
                margin-bottom: 10px;

                @include respond-below(sm) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .article-video {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        margin-bottom: 50px;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .article-gallery {
        margin-bottom: 40px;
        padding-bottom: 0;
        @include respond-below(sm) {
            margin-bottom: 30px;    
        }

        .article-gallery-top {
            display: flex;
            justify-content: space-between;

            @include respond-below(xs) {
                display: block;
            }

            .article-gallery-title {
                font-size: $base-font-size + 18;
                line-height: 44px;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 12px;

                @include respond-below(xs) {
                    font-size: $base-font-size + 8;
                    line-height: 31px;
                }
            }

            .article-gallery-info {
                padding-top: 14px;

                @include respond-below(xs) {
                    display: none;
                }
            }
        }

        .article-gallery-wrapper {
            @include respond-below(xs) {
                overflow-x: scroll;
                -webkit-overflow-scrolling: touch;
                scrollbar-width: none;
                overflow: -moz-scrollbars-none;
                -ms-overflow-style: none;

                &::-webkit-scrollbar {
                    width: 0;
                    display: none;
                }
            }

            ul {
                display: flex;
                margin: 0 -5px;

                @include respond-below(xs) {
                    margin: 0;
                    flex-wrap: wrap;
                }

                li {
                    margin: 0 5px;
                    position: relative;

                    @include respond-below(sm) {
                        margin: 0 10px 0 0;

                        &:last-child {
                            margin: 0;
                        }
                    }

                    &.with-count {
                        .gallery-count {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: rgba(255, 255, 255, 0.7);
                            text-align: center;
                            display: flex;
                            align-items: center;

                            span {
                                color: $base-font-color;
                                font-size: $base-font-size + 24;
                                width: 100%;
                                text-align: center;

                                @include respond-below(xs) {
                                    font-size: $base-font-size + 10;
                                }
                            }
                        }

                        a:hover {
                            &::after {
                                display: none;
                            }
                        }
                    }

                    a {
                        display: block;
                        position: relative;

                        &::after {
                            content: "\e908";
                            display: block;
                            font-family: "icomoon";
                            position: absolute;
                            left: calc(50% - 25px);
                            top: calc(50% - 25px);
                            font-size: $base-font-size + 2;
                            color: $white-color;
                            border: 3px solid $white-color;
                            border-radius: 50%;
                            width: 44px;
                            height: 44px;
                            line-height: 46px;
                            text-align: center;
                            display: none;

                            @include respond-below(xs) {
                                width: 34px;
                                height: 34px;
                                line-height: 34px;
                                font-size: $base-font-size - 1;
                                left: calc(50% - 20px);
                                top: calc(50% - 20px);
                            }
                        }

                        &:hover {
                            &::after {
                                display: block;
                                @include respond-below(sm) {
                                    display: none;    
                                }
                            }
                        }

                        img {
                            max-width: 100%;
                            width: 100%;

                            @include respond-below(xs) {
                                width: 110px;
                                height: 110px;
                                max-width: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .article-share-buttons {
        text-align: center;
        margin: 0 0 20px 0;

        li {
            margin: 0 6px;
            display: inline-block;
            vertical-align: top;

            @include respond-below(xs) {
                margin: 0 3px;

                &:last-child {
                    margin: 10px 3px 0 3px;

                    a {
                        font-size: $base-font-size - 3;
                    }
                }
            }

            a {
                color: $base-font-color;
                height: 43px;
                line-height: 43px;
                text-align: center;
                border: 1px solid #000;
                display: block;
                border-radius: 22px;
                padding: 0 19px;

                &:hover {
                    color: $white-color;
                    background-color: $base-link-color;
                    border: 1px solid $base-link-color;
                }

                @include respond-below(xs) {
                    height: 35px;
                    line-height: 35px;
                }
            }

            .icon-facebook1 {
                font-size: $base-font-size + 4;
                width: 43px;
                border-radius: 50%;
                padding: 0;

                @include respond-below(xs) {
                    width: 35px;
                }
            }

            .icon-messenger {
                font-size: $base-font-size - 7;
                width: 43px;
                border-radius: 50%;
                padding: 0;

                @include respond-below(xs) {
                    width: 35px;
                }
            }

            .icon-twitter {
                font-size: $base-font-size;
                width: 43px;
                border-radius: 50%;
                padding: 0;

                @include respond-below(xs) {
                    width: 35px;
                }
            }

            .icon-whatsapp {
                font-size: $base-font-size + 3;
                width: 43px;
                border-radius: 50%;
                padding: 0;

                @include respond-below(xs) {
                    width: 35px;
                }
            }

            .icon-mail {
                font-size: $base-font-size - 1;
                width: 43px;
                border-radius: 50%;
                padding: 0;

                @include respond-below(xs) {
                    width: 35px;
                }
            }

            .icon-message {
                font-size: $base-font-size + 2;
                width: 43px;
                border-radius: 50%;
                padding: 0;

                @include respond-below(xs) {
                    width: 35px;
                }
            }
        }
    }
}
