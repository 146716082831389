.article-gallery {
    padding: 0 0 60px 0;

    .article-gallery-title {
        font-size: $base-font-size + 2;
        line-height: 23px;
        margin-bottom: 20px;
    }

    .article-gallery-counter {
        font-size: $base-font-size - 2;
        line-height: 18px;
        color: $footer-link-color;
        text-align: center;
        margin-bottom: 10px;
    }

    .article-gallery-image {
        max-height: 568px;
        text-align: center;
        margin-bottom: 10px;
        position: relative;

        &:hover {
            .current-image {
                &::after {
                    opacity: 1;
                    visibility: visible;
                    @include respond-below(sm) {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
            .prev-image, .next-image {
                display: block;
            }
        }
        .prev-image, .next-image {
            position: absolute;
            top: calc(50% - 23px);
            width: 47px;
            height: 47px;
            line-height: 47px;
            text-align: center;
            border-radius: 50%;
            background-color: $dark-grey-color;
            color: $white-color;
            display: none;
            z-index: $base-z-index;
            @include transition-with-prefix('all', '200ms');
            &::after {
                content: "\e900";
                font-family: "icomoon";
                font-size: $base-font-size + 7;
            }
            &:hover {
                -webkit-transform: scale(1.15);
                transform: scale(1.15);
                background-color: $base-link-color;
                @include respond-below(sm) {
                    -webkit-transform: none;
                    transform: none;
                }
            }
            @include respond-below(sm) {
                display: block;    
            }
        }
        .prev-image {
            left: 20px;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            &:hover {
                -webkit-transform: rotate(180deg) scale(1.15);
                transform: rotate(180deg) scale(1.15);
                @include respond-below(sm) {
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);        
                }
            }
            @include respond-below(sm) {
                left: -20px;    
            }
        }
        .next-image {
            right: 20px;
            @include respond-below(sm) {
                right: -20px;    
            }
        }
        .current-image {
            display: inline-block;
            position: relative;
            &::after {
                content: "\e908";
                font-family: "icomoon";
                color: $white-color;
                font-size: $base-font-size + 2;
                position: absolute;
                left: calc(50% - 25px);
                top: calc(50% - 25px);
                width: 44px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                border-radius: 50%;
                border: 3px solid #fff;
                opacity: 0;
                visibility: hidden;
                @include transition-with-prefix('all', '200ms');
            }
            &:hover {
                &::after {
                    -webkit-transform: scale(1.4);
                    transform: scale(1.4);
                }
            }
        }

        img {
            max-height: 568px;
            display: block;
            max-width: 100%;
        }
    }

    .article-gallery-source-info {
        text-align: center;
        font-size: $base-font-size - 2;
        line-height: 18px;
        margin-bottom: 30px;

        .article-gallery-source-title {
            display: block;
        }

        .article-gallery-source {
            color: $footer-link-color;
        }
    }
    .article-gallery-thumbnail-wrapper {
        @include respond-below(xs) {
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: none;
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                width: 0;
                display: none;
            }    
        }
        
        .article-gallery-thumbnail {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px;
            @include respond-below(xs) {
                margin: 0;
            }
            li {
                margin: 0 5px 10px 5px;
                width: calc(25% - 10px);
                @include respond-below(xs) {
                    margin: 0 10px 0 0;
                    width: auto;
                    &:last-child {
                        margin: 0;
                    }
                }
                a {
                    display: block;
                    position: relative;
                    img {
                        width: 100%;
                        max-width: 100%;
                        @include respond-below(xs) {
                            width: 110px;
                            height: 110px;
                        }
                    }
                    &.current {
                        &::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            display: block;
                            background: rgba(255, 255, 255, 0.7);
                            border-radius: 0;
                            border: 0;
                        }
                    }
                    &::after {
                        content:"\e908";
                        display: block;
                        font-family: "icomoon";
                        position: absolute;
                        left: calc(50% - 25px);
                        top: calc(50% - 25px);
                        font-size: $base-font-size + 2;
                        color: $white-color;
                        border: 3px solid $white-color;
                        border-radius: 50%;
                        width: 44px;
                        height: 44px;
                        line-height: 44px;
                        text-align: center;
                        display: none;
                    }
                    &:hover {
                        &::after {
                            display: block;
                            @include respond-below(sm) {
                                display: none;
                            }
                        }
                        
                    }
                }
            }
        }    
    }
}
.gallery-lightbox {
    position: fixed;
    left: 0;
    top: 0;
    padding: 70px 0 50px 0;
    width: 100%;
    height: calc(100% - 120px);
    background-color: rgba(255, 255, 255, 0.9);
    z-index: $base-z-index + 90;
    text-align: center;
    .gallery-lightbox-close {
        position: absolute;
        left: calc(50% - 9px);
        top: 30px;
        background-color: transparent;
        cursor: pointer;
        &::after {
            content: "\e902";
            font-family: "icomoon";
            font-size: $base-font-size + 2;
        }
    }
    img {
        max-height: 100%;
        max-width: 100%;
        display: inline-block;
    }
}
