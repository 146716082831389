$font-family: 'PT Sans', sans-serif;
$base-font-size: 16px;
$font-weight-normal: 400;
$font-weight-bold: 700;
$base-z-index: 10;
$base-font-color: #000;
$base-link-color: #009CDC;
$base-width: 1140px;
$base-border-color: #d3d3d3;
$submenu-bg-color: #000000;
$dark-grey-color: #292929;
$light-grey-color: #f4f4f4;
$red-color: #E61632;
$green-color: #20AC96;
$pink-color: #EA1C64;
$orange-color: #F08A12;
$purple-color: #B31CEA;
$white-color: #fff;
$black-color: #000;
$footer-link-color: #939393;
$footer-border-color: #707070;
$predpredaj-calendar-table-thead-bg-color: #8B8B8B;
$predpredaj-calendar-event-bg-color: #484848;
$light-green-bg-color: #88C66C;
$breakpoints: (
  xxs: 480px,
  xs: 640px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xlg: 1400px
);