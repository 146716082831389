.box-predpredaj {
    background-color: $dark-grey-color;
    color: $white-color;
    padding: 38px 0 0 0;
    margin: 0 0 10px 0;
    @include respond-below(sm) {
        padding: 25px 0 0 0;    
    }
    .box-predpredaj-inner {
        max-width: $base-width;
        margin: 0 auto;
        display: flex;

        @include respond-below(lg) {
            padding: 0 30px;
        }

        @include respond-below(xs) {
            display: block;
            padding: 0 20px;
        }

        .box-predpredaj-inner-left {
            width: calc(100% - 390px);
            margin-right: 30px;

            @include respond-below(md) {
                width: calc(100% - 340px);
                margin-right: 40px;
            }

            @include respond-below(xs) {
                width: auto;
                margin-right: 0;
            }

            .box-predpredaj-inner-left-title {
                font-size: $base-font-size + 18;
                line-height: 44px;
                text-transform: uppercase;
                margin-bottom: 25px;

                @include respond-below(sm) {
                    font-size: $base-font-size + 8;
                    line-height: 31px;
                }
            }

            .box-predpredaj-slider-wrapper {
                @include respond-below(xs) {
                    overflow-x: scroll;
                    -webkit-overflow-scrolling: touch;
                    scrollbar-width: none;
                    overflow: -moz-scrollbars-none;
                    -ms-overflow-style: none;
                    &::-webkit-scrollbar {
                        width: 0;
                        display: none;   
                    }
                }

                .box-predpredaj-slider {
                    margin: 0 -5px;

                    @include respond-below(md) {
                        margin: 0 -15px;
                    }

                    @include respond-below(sm) {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0;
                    }

                    .box-predpredaj-slider-link {
                        margin: 0 5px 10px 5px;
                        width: calc(100% - 10px) !important;
                        display: block !important;
                        border: 1px solid $footer-border-color;

                        @include respond-below(md) {
                            margin: 0 15px 30px 15px;
                            width: calc(100% - 30px) !important;
                        }

                        @include respond-below(sm) {
                            margin: 0 5px 10px 5px;
                            width: calc(100% - 10px) !important;
                        }

                        @include respond-below(xs) {
                            margin: 0 10px 0 0;
                            width: 110px !important;
                            height: 110px;
                        }

                        img {
                            width: 100%;
                            max-width: 100%;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    &:hover {

                        .slick-next,
                        .slick-prev {
                            display: block !important;
                        }
                    }

                    .slick-next,
                    .slick-prev {
                        position: absolute;
                        width: 46px;
                        height: 46px;
                        line-height: 46px;
                        text-align: center;
                        background-color: $dark-grey-color;
                        color: $white-color;
                        border-radius: 50%;
                        @include transition-with-prefix('all', '200ms');
                        top: calc(50% - 42px);
                        z-index: $base-z-index;
                        cursor: pointer;
                        font-size: $base-font-size + 7;
                        display: none !important;

                        &:hover {
                            background-color: $base-link-color;
                            transform: scale(1.15);
                        }
                    }

                    .slick-next {
                        right: -18px;

                        &:hover {
                            transform: scale(1.15);
                        }

                    }

                    .slick-prev {
                        left: -18px;
                        transform: rotate(180deg);

                        &:hover {
                            transform: rotate(180deg) scale(1.15);
                        }
                    }

                    .slick-dots {
                        text-align: center;
                        height: 8px;
                        padding-top: 20px;
                        margin-bottom: 50px;
                        width: 100%;
                        @include respond-below(md) {
                            padding-top: 10px;    
                        }
                        li {
                            display: inline-block;
                            margin: 0 5px;
                            height: 8px;
                            vertical-align: top;

                            button {
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                text-indent: -9999px;
                                overflow: hidden;
                                cursor: pointer;
                                background-color: $white-color;
                                opacity: 0.3;
                                display: block;
                            }

                            &.slick-active {
                                button {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .box-predpredaj-inner-right {
            width: 360px;

            @include respond-below(md) {
                width: 300px;
            }

            @include respond-below(xs) {
                width: auto;
            }

            .box-predpredaj-inner-right-content {
                width: 300px;
                margin: 0 auto;

                @include respond-below(xs) {
                    padding: 0 0 40px 0;
                    width: 280px;
                }

                .box-predpredaj-inner-right-title {
                    font-size: $base-font-size + 2;
                    line-height: 23px;
                    text-transform: uppercase;
                    text-align: center;
                    padding-top: 5px;

                    @include respond-below(md) {
                        padding-top: 70px;
                    }

                    @include respond-below(sm) {
                        padding-top: 56px;
                    }
                    @include respond-below(xs) {
                        padding-top: 40px;
                    }
                }

                #box-predpredaj-calendar {
                    .calendar {
                        overflow: visible;

                        thead {
                            font-size: $base-font-size - 2;
                            color: $predpredaj-calendar-table-thead-bg-color;
                            font-weight: normal;
                        }

                        table {
                            margin: 0 auto;
                            font-size: $base-font-size - 2;
                            width: 260px;
                        }

                        td {
                            padding: 0 0 1px 0;
                            height: 26px;
                        }

                        .day {
                            width: 26px;
                            height: 26px;
                            line-height: 26px;
                            border: 0;

                            &.has-event {
                                background-color: $predpredaj-calendar-event-bg-color;

                                &:after {
                                    display: none;
                                }
                            }

                            &.wrong-month {
                                color: $predpredaj-calendar-event-bg-color;
                            }
                        }

                        header {
                            background-color: transparent;
                            border-bottom: 0;

                            .btn-prev {
                                display: block;
                                top: -12px;
                                left: -6px;
                            }

                            .btn-next {
                                display: block;
                                top: -12px;
                                right: -6px;
                            }

                            .month {
                                font-size: $base-font-size - 2;
                                line-height: 18px;
                                font-weight: $font-weight-normal;
                                margin-bottom: 20px;

                                .year {
                                    display: inline;
                                    font-size: $base-font-size - 2;
                                    font-weight: $font-weight-normal;
                                }
                            }

                            .simple-calendar-btn {
                                width: 20px;
                                height: 20px;
                                text-align: center;
                                line-height: 20px;
                                border-radius: 0;
                                border: 0;
                                color: $white-color;

                                &:before {
                                    border: 0;
                                    width: auto;
                                    height: auto;
                                    content: "\e900";
                                    font-family: "icomoon";
                                    font-size: $base-font-size + 4;
                                    position: relative;
                                    top: auto;
                                    left: auto;
                                }

                                &:hover {
                                    color: $base-link-color;
                                    background: transparent;
                                }
                            }
                        }

                        .filler {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}
