.menu-mobile {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $white-color;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: $base-z-index + 20;
    right: -100%;
    visibility: hidden;
    .search-form {
            position: absolute;
            left: 0;
            top: 55px;
            background-color: $black-color;
            color: $white-color;
            width: 100%;
            display: none;
            &.active {
                display: flex;
            }
            input {
                background-color: $black-color;
                color: $white-color;
                width: calc(100% - 101px);
                padding: 0 20px 0 23px;
                font-size: $base-font-size;
                @include placeholder {
                    color: $white-color;
                }
            }
            button {
                color: $white-color;
                background-color: transparent;
                font-size: $base-font-size + 2;
                width: 58px;
                height: 58px;
                line-height: 58px;
                text-align: center;
                cursor: pointer;
                &:hover {
                    color: $base-link-color;
                }
            }
    }
    @include transition-with-prefix('all', '200ms');
    &.active {
        right: 0;
        visibility: visible;
    }
    .menu-mobile-header {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 0 10px;
        margin: 0 0 30px 0;

        ul {
            display: flex;
            align-items: center;

            li {
                a {
                    font-size: $base-font-size + 2;
                    color: $base-font-color;
                    padding: 10px;
                    display: block;
                    &:hover {
                        color: $base-link-color;
                    }
                }

                button {
                    background-color: transparent;
                    font-size: $base-font-size + 2;
                    cursor: pointer;
                    padding: 10px;
                    &:hover {
                        color: $base-link-color;
                    }
                }

                .logo-predpredaj {
                    margin-left: 10px;
                    display: block;
                    padding: 0;

                    img {
                        height: 18px;
                    }
                }
            }
        }

        .icon-close {
            font-size: $base-font-size + 2;
            background: transparent;
            cursor: pointer;
            padding: 10px;
            &:hover {
                color: $base-link-color;
            }
        }
    }

    .mobile-menu-content {
        padding: 0 20px 20px 20px;

        ul {
            li {
                display: flex;
                margin-bottom: 35px;

                a {
                    font-size: $base-font-size + 2;
                    text-transform: uppercase;
                    color: $base-font-color;
                    font-weight: $font-weight-bold;
                    line-height: 23px;
                    width: 117px;
                    &:hover {
                        color: $base-link-color;
                    }
                }

                ul {
                    li {
                        margin-bottom: 0;

                        a {
                            font-weight: $font-weight-normal;
                            text-transform: none;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
